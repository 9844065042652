@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600&display=swap');
@import '~brandFolder/colors';

$spacer:1rem;
$border-width:1px;
$btn-border-radius:.25rem;
$gray-900: #2F3542 !default;
$min-contrast-ratio:   0;

.text-button{
    cursor: pointer;
}
.shadow-border{
  box-shadow: 0px 0px 2px #d5d5d5 ;
  border-radius: 10px;
  overflow: hidden;
}
.sidebar{
  a.nav-link.active-nav-link::before {
    content: '';
    height: 100%;
    width: 3px;
    background: $primary;
    position: absolute;
    left: 0px;
    top: 0px;
}
a.nav-link.active-nav-link {
    background-color: $light;
}
a.nav-link.active-nav-link .nav-icon {
    color:$primary;
}
a.nav-link.active-nav-link span{
    font-weight: bold;
}
a.nav-link:hover::before {
    content: '';
    height: 100%;
    width: 3px;
    background: $primary;
    position: absolute;
    left: 0px;
    top: 0px;
}
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .dropdown-toggle::after {
      content:'';
      display: none !important;
  }
  .icon-toggle{
    background:none !important;;
    border:none !important;
    padding:0 !important;
  }
  .icon-toggle:hover{
    background:none;
    border:none;
    box-shadow: none;
  }
  .icon-toggle:focus{
    background:none;
    border:none;
    box-shadow: none !important;
  }

  .react-tabs__tab--selected{
    border-bottom: 2px solid $primary;
    color:$primary
  }
  .react-tabs{
   
    .nav-item:hover{
      color:$primary
    }
    ul{
      list-style: none;
      padding-left: 25px !important;
      margin-bottom:0px;
    }
  }

  .opacity-0 {
    opacity:0!important;
  }
  .opacity-1 {
    opacity:0.2!important;
  }
  .opacity-2 {
    opacity:0.4!important;
  }
  .opacity-3 {
    opacity:0.6!important;
  }
  .opacity-4 {
    opacity:.8!important;
  }
  .opacity-5 {
    opacity:1!important;
  }

  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1380px,
    xxxl: 1560px
);
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1600px
  );
@import '~bootstrap/scss/bootstrap.scss';
@import 'subjects.scss';


.modal-title{
    font-weight: normal;
  }
  .modal-header{
    padding-left: 25px;
    padding-right: 25px;
    align-items: center;
    border:none;
  }
  .modal-header .close{
    font-weight: lighter;
    font-size: 2.2rem;
  }
  .modal-body{
    background-color: $light;
    padding:25px;
  }
  .modal-footer{
    padding-left: 25px;
    padding-right: 25px;
    border:none;
  }
  .form-control{
    height: calc(1.5em + 0.75rem + 15px);
  }
  .form-control::placeholder {
    font-size: 0.9rem;
  }
  .css-1wa3eu0-placeholder {
    font-size: 0.9rem;
}
  @media (min-width: 1200px) {
    .modal-dialog {
       min-width: 55%; 
    }
  }
  .css-g1d714-ValueContainer{
    height: calc(1.5em + 0.75rem + 15px);
  }
  .css-g1d714-ValueContainer:focus{
    border:none;
  }
  body{
    background-color: $light;
    min-height: 100vh;
  }
  .text-black {
    font-weight: 900;
  }
  
  .text-extra-bold {
    font-weight: 800;
  }
  
  .text-bold {
    font-weight: 700;
  }
  
  .text-semi-bold {
    font-weight: 600;
  }
  
  .text-medium {
    font-weight: 500;
  }
  
  .text-normal {
      font-weight: 400;
  }
  
  .text-light {
    font-weight: 300;
  }
  
  .text-extra-light {
    font-weight: 200;
  }
  
  .text-thin {
    font-weight: 100;
  }
  .RichTextEditor__root___2QXK-{
    min-height: 250px;
  }

  .clickupModal{
    .modal-content{
      height: calc(100vh - 56px) !important;
    }
  }


.loading-icon {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
.main-container{
  width: 100%;
}
.login-form{
  width:100%
}
@media (min-width: 768px) { 
  .main-container{
    width: calc(100% - 260px)
  }
  .login-form{
    max-width: 50%;
    
  }
}

.closeIcon{
  border-radius: 999px;
    position: absolute;
    left: 270px;
    z-index: 999;
    border: 2px solid $primary;
    width: 30px;
    height: 30px;
    top: 13px;
    color: $primary;
    font-weight: bold;
    cursor: pointer;
}

.blank-word:hover{
  background-color: $primary;
  color:white;
}
.icon-choose{
  border:2px solid $light;
}

.icon-choose:hover{
  background-color: #efefef ;
  border: 2px solid $primary;
}

.icon-choose-active{
  border: 2px solid $primary;
}

.blank-word{
  cursor: pointer;
}

.flex-break{
    flex-basis: 100%;
    width: 0;
}
.modal-body-container{
  display:flex;
  justify-content: space-between;
  flex-direction: column;
}
.modal-content-container-fixed{
  height: 600px;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
}

.modal-content-container{
  height: 100%;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
}
.modal-content-scroll{
  overflow:hidden;
  overflow-y: scroll;
}
.multi-step{
  height: 100%;
}
.ReactCrop__image {
  max-height: 300px;
}
.modal-fullscreen{
  .modal-dialog{
    margin:0px;
  }
  .modal-content{
    width: 100vw;
    height: 100vh;
  }
  .modal-body-container{
    height: 100%;
  }
  .modal-content-container-fixed{
    height: 100% !important;
  }

}
.fullscreen-icon{
  opacity: 0.7;
  cursor: pointer;
}
.fullscreen-icon:hover{
  opacity: 1;
}
.h-90{
  height:90%
}
.preview-image-delete{
  display:none;
}
.preview-image:hover{
  .preview-image-delete{
    display:block;
  }
}

.SelectedWeekExample .DayPicker-Month {
  border-collapse: separate;
}
.SelectedWeekExample .DayPicker-WeekNumber {
  outline: none;
}
.SelectedWeekExample .DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.SelectedWeekExample .DayPicker-Day--hoverRange {
  background-color: #EFEFEF !important;
}

.SelectedWeekExample .DayPicker-Day--selectedRange {
  background-color: $primary !important;
  border-top-color: $primary;
  border-bottom-color: $primary;
  border-left-color: $primary;
  border-right-color: $primary;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeStart {
  background-color: $primary !important;
  border-left: 1px solid $primary;
}

.SelectedWeekExample .DayPicker-Day--selectedRangeEnd {
  background-color: $primary !important;
  border-right: 1px solid $primary;
}

.SelectedWeekExample .DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.SelectedWeekExample .DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: white !important;
}
.SelectedWeekExample .DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}
.DayPicker-Month{
  margin:0;
}
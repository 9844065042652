.subject-Elektrotechnik{
    background:#FF7F50;
    color:white;
}
.subject-Chemie{
    background:#7bed9f;
}
.subject-Erdkunde{
    background:#e15f41;
    color:white;
}
.subject-Englisch{
    background:#ff4757;
    color:white;
}
.subject-Gemeinschaftskunde{
    background:#eccc68
}
.subject-Mathematik{
    background:#5352ed;
    color:white;
}
.subject-Biologie{
    background:#1e90ff;
    color:white;
}
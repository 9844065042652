$primary:#4F46E5;
$secondary:#6F768B;
$success:#0074E4;
$danger:#FF4057;
$info:#74DBEF;
$warning:#FC3C3C;
$light:#F8F9F9;
$dark:#072247;

$headings-font-family:'Rubik';
$font-family-base:'Rubik';